import { Expose, Type } from 'class-transformer';
import { string2money } from 'common/utils/string';
import { selectLanguage } from 'hooks/use-language';

// Enum

export enum PivotAggregateFormatEnum {
  money = 'money',
  numeric = 'numeric',
  percentage = 'percentage',
  stringWithNewlines = 'string_eol',
}

export enum ColumnEnum {
  string = 'string',
  number = 'number',
  integer = 'integer',
  date = 'date',
  nested = 'nested',
}

// ENUM

export enum StatusEnum {
  PENDING = 'pending',
  CONFIRMED = 'confirmed',
  CANCELED = 'canceled',
}

// Model

export class MinimalModel {
  id: string;
  name: string;
}

export class EnumResultModel {
  label: string;
  value: string;
}

export class ColumnReportModel {
  name: string;
  label: string;
  type: ColumnEnum;
  columns?: ColumnReportModel[];
}

export class FileResultModel {
  id: string;
  name: string;
  url: string;
}

export class CommonModel {
  id: string;

  @Expose({ name: 'created_at' })
  @Type(() => Date)
  createdAt: Date;

  @Expose({ name: 'updated_at' })
  @Type(() => Date)
  updatedAt: Date;
}

export class PivotAggregateRowModel {
  name: string;
  label: string;
  link?: string | null;
  badge?: string | null;
  format: PivotAggregateFormatEnum;
  array_accessor?: string;
}

export class PivotRowModel {
  name: string;
  label: string;
  link?: string | null;
  badge?: string | null;
  array_accessor?: string;

  @Expose({ name: 'show_total' })
  @Type(() => Boolean)
  showTotal: boolean;
}

export class PivotItemModel {
  @Type(() => PivotAggregateRowModel)
  aggregates: PivotAggregateRowModel[];

  @Type(() => PivotRowModel)
  rows: PivotRowModel[];
}

export class PivotModel {
  @Type(() => PivotItemModel)
  default: PivotItemModel;

  @Type(() => PivotItemModel)
  value: PivotItemModel;

  @Expose({ name: 'is_editable' })
  @Type(() => Boolean)
  isEditable: boolean;
}

// Input

export type FileInputType = {
  id?: string;
  file: string | null;
  url?: string | null;
};

export type getEnumsInput = {
  class: string;
};

export interface getParamsInput<
  Params extends { [key: string]: any } = object,
> {
  params: {
    page?: number;
    filter?: { [key: string]: any };
    sort?: string;
    limit?: number;
    q?: string;
  } & Params;
}
export interface CommonUpdateInput<TBody> {
  id: string;
  body: TBody;
}
export interface IdInput {
  id: string;
}

export enum CurrencyEnum {
  USD = 'USD',
  IDR = 'IDR',
}
export enum MoneyValueType {
  Percentage = 'percentage',
  Rupiah = 'rupiah',
}
export class MoneyValueItemModel {
  id: string;
  order: number;
  type: MoneyValueType;

  @Type(() => Number)
  value: number;

  static asString(item: MoneyValueItemModel) {
    return `${
      item.type === MoneyValueType.Rupiah
        ? string2money(item.value)
        : item.value
    }${item.type === MoneyValueType.Percentage ? '%' : ''}`;
  }

  static asMultipleNumberInput(item: MoneyValueItemModel) {
    return `${MoneyValueItemModel.asString(item)}@${item.id}`;
  }
}

export class LocalizedName {
  get name() {
    return selectLanguage({
      en: this.nameEn,
      id: this.nameId,
    });
  }

  @Expose({ name: 'name' })
  nameEn: string;

  @Expose({ name: 'name_id' })
  nameId: string;
}
