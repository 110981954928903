import { LocalStorageKeys } from 'common/constants/browser-storage-keys';
import { AvailableLanguageEnum } from 'common/constants/enum';
import { differenceInHours, format } from 'date-fns';
import { enUS as en } from 'date-fns/locale/en-US';
import { id } from 'date-fns/locale/id';
import { TFunction } from 'i18next';

import regex from './regex';

export function tryParseDate(candidate: any): Date | undefined;
export function tryParseDate(candidate: any, defaultValue: Date): Date;
export function tryParseDate(
  candidate: any,
  defaultValue: Date | undefined = undefined,
): Date | undefined {
  const date = new Date(candidate);
  if (isNaN(date.getTime())) {
    return defaultValue;
  }
  return date;
}

export const convertDate = function (date) {
  const time = new Date(date?.getTime());
  time?.setHours(0, 0, 0, 0);
  return time?.toISOString();
};

export const getMonthRange = ({
  month,
  year,
}: {
  month?: number;
  year?: number;
}): { lastDay: Date; firstDay: Date } => {
  const _today = new Date(year!, month!);
  const lastDay = new Date(_today.getFullYear(), _today.getMonth() + 1, 0);
  const firstDay = new Date(_today.getFullYear(), _today.getMonth(), 1);
  return { lastDay, firstDay };
};

export function getDateLocale() {
  // use this than getLanguageStorage from use-language. Utils should not import an application hook.
  const language = localStorage.getItem(LocalStorageKeys.Language);
  if (language === AvailableLanguageEnum.En) {
    return en;
  } else {
    return id;
  }
}

export function formatDate(date: Date) {
  return format(date, 'dd MMM yyyy', {
    locale: getDateLocale(),
  });
}

export function formatDateTime(date: Date) {
  return format(date, 'dd MMMM yyyy, HH:mm', {
    locale: getDateLocale(),
  });
}

export function formatDateDifference(a: Date, b: Date, t: TFunction) {
  const totalHours = differenceInHours(b, a);
  const days = Math.floor(totalHours / 24);
  const hours = totalHours % 24;
  const dateStringBuilder: string[] = [];
  if (days > 0) {
    dateStringBuilder.push(
      `${days} ${t('common:day', { count: days }).toLowerCase()}`,
    );
  }
  if (hours > 0 || (days === 0 && hours === 0)) {
    dateStringBuilder.push(
      `${hours} ${t('common:hour', { count: hours }).toLowerCase()}`,
    );
  }
  return dateStringBuilder.join(' ');
  // return formatDistance(a, b, {
  //   locale: getDateLocale(),
  // });
}

export function formatTimezone(date: Date) {
  // Very little difference between GMT and UTC. We can treat them as the same: https://stackoverflow.com/questions/48942916/what-is-the-difference-between-utc-and-gmt
  return format(date, 'OOO').replace('GMT', 'UTC');
}

export function formatDateTimeWithTimezone(date: Date) {
  return `${formatDateTime(date)} (${formatTimezone(date)})`;
}

export function hoursToMiliseconds(hours: number) {
  return hours * 60 * 60 * 1000;
}

export function minutesToMiliseconds(minutes: number) {
  return minutes * 60 * 1000;
}

export function isValidDate(obj: any): obj is Date {
  return (
    obj &&
    'getTime' in obj &&
    typeof obj.getTime === 'function' &&
    !isNaN(obj.getTime())
  );
}

export class KurosimTimezone {
  static readonly key = 'kurosim-timezone';

  static set() {
    const now = new Date().getTimezoneOffset();
    const hours = Math.floor(Math.abs(now) / 60);
    const minutes = Math.abs(now) % 60;
    const value = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    const result = {
      offset: now,
      timezone: now === 0 ? '00:00' : now > 0 ? `-${value}` : `+${value}`,
    };

    localStorage.setItem(KurosimTimezone.key, JSON.stringify(result));
    return result;
  }

  static get() {
    const value = localStorage.getItem(KurosimTimezone.key);
    if (typeof value === 'string') {
      const result = JSON.parse(value);
      const now = new Date().getTimezoneOffset();
      if (result?.offset === now && regex.timezone.test(result?.timezone)) {
        return result?.timezone;
      }
    }
    return KurosimTimezone.set().timezone;
  }

  static reset() {
    localStorage.removeItem(KurosimTimezone.key);
    KurosimTimezone.set();
  }
}
