import {
  QueryFilters,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { StaleTimes } from 'api-hooks/common';
import { plainToInstance } from 'class-transformer';
import { GET_ME_KEY } from 'common/api/constants';
import { QueryFetchFunction, QueryTransformer } from 'common/api/fetch';
import { ApiQueryFunction } from 'common/api/fetch-types';
import { meClient } from 'common/api/ky-client';
import { ApiError, ApiResult } from 'common/api/model';

import { UserModel } from './model';

export const meKey = {
  getMeKey: GET_ME_KEY,
  getCacheTokenKey: ['getCacheToken'],
};

export const getMe = async function (): Promise<UserModel> {
  const response = await meClient.get('me').json<ApiResult<UserModel>>();
  return plainToInstance(UserModel, response.data) as never as UserModel;
};

/** For user-land stuff, please use useAuth since that also synchronizes the me value with Native */
export function useGetMe(
  options?: UseQueryOptions<ApiResult<UserModel>, ApiError>,
): UseQueryResult<ApiResult<UserModel>, ApiError> {
  const result = QueryTransformer(
    useQuery(
      meKey.getMeKey,
      async () => {
        const result = QueryFetchFunction({
          url: 'me',
          options: {
            retry: undefined,
          },
          client: meClient,
        });

        return result;
      },
      {
        // We can afford to let this last longer since ky-client automatically invalidates this query upon receiving a 401 error.
        staleTime: StaleTimes.Medium,
        ...options,
      },
    ),
    UserModel,
  ) as UseQueryResult<ApiResult<UserModel>, ApiError>;

  const { error, status } = result;
  if (error || status !== 'success') {
    return { ...result, data: undefined } as never as UseQueryResult<
      ApiResult<UserModel>,
      ApiError
    >;
  }
  return result;
}

export const useGetHttpCacheToken: ApiQueryFunction<
  never,
  ApiResult<{
    public: string;
  }>
> = function (options) {
  return QueryTransformer(
    useQuery(
      meKey.getCacheTokenKey,
      async () =>
        QueryFetchFunction({
          url: 'cache-token',
        }),
      {
        staleTime: Infinity,
        cacheTime: Infinity,
        ...options,
      },
    ),
    UserModel,
  );
};

export const allQueryExceptToken: QueryFilters['predicate'] = (query) => {
  return query?.queryKey?.[0] !== meKey.getCacheTokenKey[0];
};
