import 'reflect-metadata';
import '@mantine/core/styles.css';
import '@mantine/carousel/styles.css';
import '@mantine/dates/styles.css';
import 'react-spring-bottom-sheet/dist/style.css';
import 'styles/globals.css';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { HTTPCacheTokenProvider } from 'common/api/http-cache-token';
import { queryClient } from 'common/api/query-client';
import { HandleRefreshOauthComponent } from 'common/auth';
import { AssetPaths } from 'common/constants/assets';
import resetStyle from 'common/constants/reset-style';
import 'common/i18n';
import RouteTitle from 'common/routes/titles';
import { uniqueByKeepLast } from 'common/utils/iterable';
import PrivateRoute from 'components/common/private-routes';
import KurosimNavigationBridgeController from 'components/kurosim-navigation-bridge-controller';
import KurosimTimezoneCorrection from 'components/kurosim-timezone-correction';
import PrefixUrlModifier from 'components/prefix-url-modifier';
import { NotFoundIndicatorProvider } from 'hooks/use-get-not-found-indicator';
import { NotificationProvider } from 'hooks/use-get-notification-permission';
import { SmallScreenProvider } from 'hooks/use-is-small-screen';
import {
  InterPageDataProvider,
  StackNavigation,
  TabNavigation,
} from 'hooks/use-kurosim-navigation';
import { KurosimNavigatorPopStateHandler } from 'hooks/use-kurosim-navigation/popstate';
import KurosimNavigationPrefetcher from 'hooks/use-kurosim-navigation/prefetch';
import { LanguageProvider } from 'hooks/use-language';
import { ParentRefProvider } from 'hooks/use-parent-ref';
import PushNotificationHandler from 'hooks/use-push-notification';
import { CrispProvider } from 'modules/profile/screens/live-chat';
import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { IBM_Plex_Sans } from 'next/font/google';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { SessionProvider } from 'next-auth/react';
import React, { Suspense, useEffect } from 'react';
import { theme } from 'styles/theme';

import '@mantine/notifications/styles.css';
import LoadingViewComponent from './loading';

const ibmPlexSans = IBM_Plex_Sans({
  subsets: ['latin'],
  weight: ['100', '200', '300', '400', '500', '600', '700'],
  variable: '--font-ibm_plex_sans',
});

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function App({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();
  const { pathname, query } = router;

  const { session } = pageProps;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const __next = document.getElementById('__next');
      if (!__next) return;
      __next.className = `${ibmPlexSans.variable}`;
    }
  }, []);

  const getLayout = Component.getLayout ?? ((page) => page);

  //for initiate state of navigation
  React.useEffect(() => {
    if (typeof window === 'undefined') return;
    TabNavigation.set(TabNavigation.getDefaultValues());

    let stack = StackNavigation.get();

    // Prune all lck routes
    stack = stack.filter((route) => !route.query || !route.query.lck);

    // Add new route
    stack.push({
      pathname,
      query,
    });

    // Make sure that stack is unique
    stack = uniqueByKeepLast(stack, (entry) => entry.pathname);

    // This way, we preserve previous route history without breaking things (hopefully)
    StackNavigation.set(stack);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MantineProvider
        forceColorScheme="light"
        theme={theme({ fontFamily: ibmPlexSans.style.fontFamily })}
      >
        <Head>
          <meta
            name="viewport"
            content="width=device-width, height=device-height, initial-scale=1, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
          />
          <meta name="robots" content="noindex,nofollow" />
          <link rel="icon" href="/favicon.ico" />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
          />
          <link rel="manifest" href="/manifest.json" />

          {/* We'll load onboarding assets as early as possible so that new users are not greeted with a blank screen */}
          <link
            rel="preload"
            href={AssetPaths.OnboardingBackgroundFirst}
            as="image"
          />
          <link
            rel="preload"
            href={AssetPaths.OnboardingBackgroundSecond}
            as="image"
          />
          <link rel="preload" href={AssetPaths.OnboardingFirst} as="image" />
          <link rel="preload" href={AssetPaths.OnboardingSecond} as="image" />
          <link rel="preload" href={AssetPaths.SimSkinBlue} as="image" />
          <link
            rel="preload"
            href={AssetPaths.SimSkinBlueAbstract}
            as="image"
          />
          <link rel="preload" href={AssetPaths.SimSkinDark} as="image" />
          <link rel="preload" href={AssetPaths.SimSkinGreen} as="image" />
          <link rel="preload" href={AssetPaths.SimSkinKPattern} as="image" />
          <link rel="preload" href={AssetPaths.SimSkinOrange} as="image" />
          <style>{resetStyle}</style>
        </Head>
        <Notifications
          limit={10}
          zIndex={9999999}
          autoClose={4000}
          position="top-center"
          mt="env(safe-area-inset-top)"
        />
        <RouteTitle />
        <SessionProvider session={session}>
          <NotFoundIndicatorProvider>
            <ParentRefProvider>
              <KurosimNavigatorPopStateHandler>
                <InterPageDataProvider>
                  <QueryClientProvider client={queryClient}>
                    <HTTPCacheTokenProvider>
                      <LanguageProvider>
                        <CrispProvider>
                          <HandleRefreshOauthComponent />
                          <PushNotificationHandler />
                          <KurosimNavigationPrefetcher />
                          <KurosimTimezoneCorrection />
                          <NotificationProvider>
                            <PrivateRoute>
                              <SmallScreenProvider>
                                <KurosimNavigationBridgeController />
                                {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                                {getLayout(
                                  <Suspense
                                    fallback={<LoadingViewComponent fixed />}
                                  >
                                    <Component {...pageProps} />
                                  </Suspense>,
                                )}
                                <PrefixUrlModifier />
                              </SmallScreenProvider>
                            </PrivateRoute>
                          </NotificationProvider>
                        </CrispProvider>
                      </LanguageProvider>
                    </HTTPCacheTokenProvider>
                  </QueryClientProvider>
                </InterPageDataProvider>
              </KurosimNavigatorPopStateHandler>
            </ParentRefProvider>
          </NotFoundIndicatorProvider>
        </SessionProvider>
      </MantineProvider>
    </>
  );
}

export default App;
