import { AvatarProfileTypeEnum } from 'api-hooks/account';
import { Expose, Type } from 'class-transformer';
import { CaptchaTokenInput } from 'common/api/model';
import {
  AffiliateRequestStatusEnum,
  DeleteUserReasonEnum,
  OtpActionEnum,
  UserLevelEnum,
  UserStatusEnum,
} from 'common/constants/enum';

export type LanguageType = 'en' | 'id';

// Model
export class UserSettingsModel {
  @Expose({ name: 'push_notification' })
  pushNotification: boolean;

  @Expose({ name: 'email_notification' })
  emailNotification: boolean;

  @Expose({ name: 'notice_before_payment' })
  noticeBeforePayment: boolean;

  language: LanguageType;

  timezone: string | null;
}

export type AuthProviderType = 'google' | 'apple' | 'none';

export class UserWalletModel {
  id: string;
  name: string;

  @Expose({ name: 'currency_code' })
  currencyCode: string;

  @Type(() => Number)
  amount: number;

  status: UserStatusEnum;
}

export class UserLiteModel {
  id: string;
  email: string;

  @Expose({ name: 'first_name' })
  firstName: string;

  @Expose({ name: 'last_name' })
  lastName: string | null;

  get fullName() {
    return [this.firstName, this.lastName].filter(Boolean).join(' ');
  }

  @Expose({ name: 'avatar_profile_type' })
  avatarProfileType: AvatarProfileTypeEnum;

  @Expose({ name: 'phone_number' })
  phoneNumber: string | null;

  @Expose({ name: 'created_at' })
  @Type(() => Date)
  createdAt: Date;
}

export class UserModel extends UserLiteModel {
  level: UserLevelEnum;
  status: UserStatusEnum;

  @Expose({ name: 'auth_provider' })
  authProvider: AuthProviderType;

  @Expose({ name: 'email_verified_at' })
  @Type(() => Date)
  emailVerifiedAt: Date | null;

  @Expose({ name: 'date_of_birth' })
  @Type(() => Date)
  dateOfBirth: Date;

  @Type(() => UserSettingsModel)
  settings: UserSettingsModel;

  @Type(() => UserWalletModel)
  wallet: UserWalletModel;

  @Expose({ name: 'affiliate_request_status' })
  affiliateRequestStatus: AffiliateRequestStatusEnum;

  @Expose({ name: 'bound_referral' })
  boundReferral: string | null;
}

export class VerifyOtpModel {
  @Expose({ name: 'verification_token' })
  verificationToken: string | null;

  @Expose({ name: 'wait_time' })
  @Type(() => Date)
  waitTime: Date | null;
}

export class CheckEmailModel {
  // status: CheckEmailStatusEnum;
  @Expose({ name: 'in_use' })
  inUse: boolean;

  provider: AuthProviderType;
}

export class SendOtpResultModel {
  @Expose({ name: 'wait_time' })
  @Type(() => Date)
  waitTime: Date;
}

export class CheckEmailMutationInput extends CaptchaTokenInput {
  email: string;
}

export class RegisterMutationInput extends CaptchaTokenInput {
  email: string;
  password: string;
  passwordConfirmation: string;
  referralCode: string | null;
  pushNotification: boolean;
  timezone: string | null; // 00:00, +07:00, -07:00
}

export class SendOtpMutationInput {
  email: string;
  action: OtpActionEnum;
}

export class VerifyOtpMutationInput {
  email: string;
  action: OtpActionEnum;
  otp: string;
}

export class LoginMutationInput extends CaptchaTokenInput {
  email: string;
  password: string;
  timezone: string | null; // 00:00, +07:00, -07:00
}

export class RefreshTokenMutationInput {
  refreshToken: string;
}

export class ChangeEmailMutationInput {
  verificationToken: string;
  newEmail: string;
}

export class VerifyEmailMutationInput {
  verificationToken: string;
}

export class ChangePasswordMutationInput {
  oldPassword: string;
  password: string;
  passwordConfirmation: string;
  verificationToken: string;
}

export class DeleteAccountMutationInput {
  deletionReason: DeleteUserReasonEnum;
  customReason: string;
}

export class ViaProviderMutationInput {
  provider: string;
  idToken: string;
  familyName: string | null;
  givenName: string | null;
  language?: string;
  timezone: string | null; // 00:00, +07:00, -07:00
}

export class ResetPasswordMutationInput extends CaptchaTokenInput {
  verificationToken: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}

export class RevokeMutationInput {
  accessToken: string;
}

export class PatchSubscribeInput {
  subscriptionId: string;
}
