import i18n from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

import { LocalStorageKeys } from './constants/browser-storage-keys';

i18n
  .use(initReactI18next)
  .use(
    resourcesToBackend(
      (language, namespace) =>
        import(`../../locales/${language}/${namespace}.json`),
    ),
  )
  .init({
    // do not load a fallback
    returnEmptyString: false,
    supportedLngs: ['en', 'id'],
    lng: 'id',
    ns: [
      'auth',
      'common',
      'enum',
      'error',
      'example',
      'home',
      'profile',
      'referral',
      'sim',
      'title',
      'transaction',
    ],
    keySeparator: false,
    fallbackNS: ['common'],
  });

// Change language at the start
if (typeof window !== 'undefined') {
  const languagePreference = localStorage.getItem(LocalStorageKeys.Language);
  i18n.changeLanguage(languagePreference ?? 'id');
}

export default i18n;
