import { LocalStorageKeys } from 'common/constants/browser-storage-keys';
import { KurosimTimezone } from 'common/utils/date';

import { HighlightInstallationStamp } from './highlight-installation-stamp';
import {
  getLanguageStorage,
  setLanguageStorage,
} from '../../hooks/use-language';

export default function removeUserData() {
  const httpCacheToken = localStorage.getItem(LocalStorageKeys.HttpCacheToken);
  const language = getLanguageStorage();

  //clear all storages
  localStorage.clear();

  localStorage.setItem(LocalStorageKeys.HasShownOnboarding, 'true');
  HighlightInstallationStamp.set();
  KurosimTimezone.set();
  if (language) {
    setLanguageStorage(language);
  }
  if (httpCacheToken) {
    localStorage.setItem(LocalStorageKeys.HttpCacheToken, httpCacheToken);
  }
}
