import { createTheme } from '@mantine/core';

// Load date locales
import 'dayjs/locale/en';
import 'dayjs/locale/id';

export const theme = (props: { fontFamily: string }) =>
  createTheme({
    colors: {
      brand: [
        '#FFFFFF',
        '#F2F2F2',
        '#D9D9D9',
        '#B3B3B3',
        '#8C8C8C',
        '#4C4C4C',
        '#000000',
        '#000000',
        '#000000',
        '#000000',
      ],
    },
    primaryColor: 'brand',
    fontFamily: props.fontFamily,
  });
