import { useUpdateSettings } from 'api-hooks/account';
import { queryClient } from 'common/api/query-client';
import { useAuth } from 'common/auth';
import { KurosimTimezone } from 'common/utils/date';
import React from 'react';

export default function KurosimTimezoneCorrection() {
  const { data, isAuthenticated } = useAuth();
  const { mutateAsync } = useUpdateSettings();
  const meTimezone = data?.data?.settings?.timezone;

  React.useEffect(() => {
    if (typeof window === 'undefined') return;

    async function correction() {
      const timezone = KurosimTimezone.get();
      if (!isAuthenticated) return;
      if (typeof meTimezone === 'string' && meTimezone === timezone) return;
      try {
        await mutateAsync({ timezone });
        queryClient.invalidateQueries();
      } catch (e) {
        console.error(e);
      }
    }

    window.addEventListener('focus', correction);
    return () => {
      window.removeEventListener('focus', correction);
    };
  }, [isAuthenticated, meTimezone, mutateAsync]);

  return null;
}
