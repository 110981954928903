// This file stores all functions related to the HTTP Cache Token

import { useLocalStorage } from '@mantine/hooks';
import { useGetHttpCacheToken } from 'api-hooks/auth';
import { LocalStorageKeys } from 'common/constants/browser-storage-keys';
import React from 'react';

export const HTTPCacheTokenContext = React.createContext<string | null>(
  undefined as any,
);
export function useHttpCacheToken() {
  const token = React.useContext(HTTPCacheTokenContext);
  const TOKEN_KEY = 'token';
  return React.useMemo(() => {
    return {
      token: token ?? '',
      apply(params: object | undefined) {
        if (!params) {
          params = {};
        }
        if (token) {
          params[TOKEN_KEY] = token;
        }
        return params;
      },
    };
  }, [token]);
}

/** We use HTTP cache to cache data from certain endpoints. Those endpoints will have ``Cache-Control`` headers in the response which informs the browser about how long and how should they cache the data. In order to ensure that the cache can be busted (i.e.: renewing cache data with new data), we'll get a special token from backend. This token will be included in the URL as in: <endpoint>?token=<token>. That url will be used as the identifier for the cache, and so long as the token is not changed, the browser will keep on reusing data from the cache. */
export function HTTPCacheTokenProvider(props: React.PropsWithChildren) {
  const initialCacheValue =
    typeof window !== 'undefined'
      ? localStorage.getItem(LocalStorageKeys.HttpCacheToken)
      : null;
  const [token, setToken] = useLocalStorage<string | null>({
    key: LocalStorageKeys.HttpCacheToken,
    defaultValue: initialCacheValue,
    serialize: (value) => value || '',
    deserialize: (value) => value || null,
  });
  const backendToken = useGetHttpCacheToken();

  const newToken = backendToken.data?.data?.public;
  React.useEffect(() => {
    if (newToken) {
      setToken(newToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newToken]);

  return (
    <HTTPCacheTokenContext.Provider value={token}>
      {props.children}
    </HTTPCacheTokenContext.Provider>
  );
}
