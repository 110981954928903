import { allQueryExceptToken, useRevoke } from 'api-hooks/auth';
import { queryClient } from 'common/api/query-client';
import notification from 'common/helpers/notification';
import removeUserData from 'common/repositories/remove-user-storage';
import { NavigationRoutes } from 'common/routes';
import useNativeBridge from 'common/routes/bridge';
import { BridgeMessageType } from 'common/routes/bridge-types';
import { isPublicRoutes } from 'components/common/private-routes';
import Separator from 'components/common/separator';
import Button from 'components/elements/button';
import Text from 'components/elements/text';
import BottomSheet, {
  BottomSheetRemote,
} from 'components/widgets/bottom-sheet';
import useDetectDevice from 'hooks/use-detect-device';
import { useIsSmallScreen } from 'hooks/use-is-small-screen';
import { OneSignalLogout } from 'hooks/use-push-notification';
import { signOut } from 'next-auth/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SessionToken } from './token';
import { syncMeData } from './use-auth';
import { useConfirmDeleteDialog } from '../../hooks/use-dialog';
import useKurosimNavigation, {
  backWithoutHistory,
  StackNavigation,
  TabNavigation,
} from '../../hooks/use-kurosim-navigation';

export async function clearSessionData() {
  SessionToken.clear();
  syncMeData(undefined);

  try {
    await signOut({
      redirect: false,
    });
  } catch (e) {
    console.error(e);
  }
}

interface UseLogoutDialogProps {
  isLoading: boolean;
  message?: string;
  title?: string;
  onConfirm(onClose?: () => void): void;
}

/** Used in logout and linked devices logout others. Consider adding new props if there is behavior specific to either of those. */
export function useLogoutDialog(props: UseLogoutDialogProps) {
  const {
    isLoading: isLoadingLogout,
    onConfirm: onConfirmLogout,
    title,
    message,
  } = props;
  const { t } = useTranslation();
  const { Dialog: DialogLogout, open: openLogout } = useConfirmDeleteDialog({
    content: message ?? t('auth:logout_warning_description'),
    titleProps: {
      ta: 'center',
    },
    contentProps: {
      ta: 'center',
    },
    title: title ?? t('common:logout'),
    confirmationButtonProps: {
      loading: isLoadingLogout,
      onClick: ({ onClose }) => {
        onConfirmLogout(onClose);
      },
      children: t('common:confirm'),
      variant: {
        theme: 'error',
      },
    },
    cancelButtonProps: {
      onClick: async ({ onClose }) => {
        onClose();
      },
      children: t('common:cancel'),
      variant: {
        size: 'default',
        variant: 'secondary',
      },
    },
  });

  const bottomSheetLogoutRef = React.useRef<BottomSheetRemote | null>(null);
  const BottomSheetLogout = (
    <BottomSheet
      ref={bottomSheetLogoutRef}
      title={title ?? t('common:logout')}
      defaultSnap={240}
      maxHeight={240}
    >
      <Text textVariant="body1Medium" ta="center">
        {message ?? t('auth:logout_warning_description')}
      </Text>
      <Separator gap={16} />
      <Button
        fullWidth
        variant={{
          theme: 'error',
        }}
        onClick={() => onConfirmLogout(bottomSheetLogoutRef.current?.close)}
      >
        {t('common:confirm')}
      </Button>
    </BottomSheet>
  );

  const isMobile = useIsSmallScreen();
  const onOpenLogout = isMobile
    ? () => {
        bottomSheetLogoutRef.current?.open();
      }
    : openLogout;

  return {
    Dialog: isMobile ? BottomSheetLogout : DialogLogout,
    open: onOpenLogout,
  };
}

interface UseLogoutProps {
  /** Function that executes after session and user data is cleared, but before navigating the user away. */
  afterLogoutHook?(): Promise<void>;
  /** Function that executes after user has confirmed logout, but before the access token is revoked. */
  beforeLogoutHook?(): Promise<void>;
  /** Custom logout function */
  customLogout?(): Promise<void>;
  /** Should revoke auth be called? This is useful if a function that revokes existing session tokens had been called beforehand, to avoid a redundant API call. */
  shouldRevokeAuth?: boolean;
}

export function useLogout(props: UseLogoutProps) {
  const {
    afterLogoutHook,
    beforeLogoutHook,
    customLogout,
    shouldRevokeAuth = true,
  } = props;
  const mutate = useRevoke();
  const [isLoadingLogout, setLoadingLogout] = React.useState(false);
  const { mutateAsync: revokeAsync } = mutate;
  const { refresh } = useKurosimNavigation();
  const { isKurosimApp } = useDetectDevice();
  const send = useNativeBridge({});

  const handleLogout = React.useCallback(async () => {
    if (shouldRevokeAuth) {
      // Revoke access token
      const accessToken = SessionToken.get()?.accessToken;
      if (!accessToken) return;
      try {
        const result = await revokeAsync({ accessToken });
        result.message && notification.success({ message: result.message });
      } catch (e) {
        // Ignore 401 error. This may happen if user has already unauthenticated through other means (e.g.: change password, delete account)
        if (e?.statusCode !== 401 && e?.message) {
          notification.error({ message: e.message });
        }
        console.error(e);
      }
    }

    // Sync logout state with app and OneSignal
    try {
      if (isKurosimApp) {
        await send?.({
          data: null,
          type: BridgeMessageType.Logout,
        });
        // Mobile app handles one signal logout
      } else {
        OneSignalLogout();
      }
    } catch (e) {
      console.error(e);
      notification.error({ message: e.message });
    }

    // Clear session data (token, me data, next-auth session)
    try {
      await clearSessionData();
    } catch (e) {
      console.error(e);
      notification.error({ message: e.message });
    }

    // Clear user data
    removeUserData();

    await afterLogoutHook?.();

    // Preserve history
    const stack = StackNavigation.get();
    const tabs = TabNavigation.get();
    sessionStorage.clear();

    // Remove all authorized routes from current stack navigation
    const publicRoutes = stack.filter((x) => isPublicRoutes(x.pathname));
    if (publicRoutes.length === 0) {
      // Fallback
      publicRoutes.push({
        pathname: backWithoutHistory(
          stack[stack.length - 1]?.pathname as NavigationRoutes,
        ),
        query: {},
      });
    }
    StackNavigation.set(publicRoutes);
    TabNavigation.set(tabs);

    queryClient.invalidateQueries({ predicate: allQueryExceptToken });
    queryClient.clear();

    // Return to latest public route
    refresh();
  }, [
    afterLogoutHook,
    isKurosimApp,
    refresh,
    revokeAsync,
    send,
    shouldRevokeAuth,
  ]);

  const onConfirmLogout = React.useCallback(
    async (onClose?: () => void) => {
      try {
        setLoadingLogout(true);
        await beforeLogoutHook?.();
        if (customLogout) {
          await customLogout();
        } else {
          await handleLogout();
        }
        onClose?.();
      } catch (e) {
        console.error(e);
        onClose?.();
        notification.error({
          message: e.message,
        });
      } finally {
        setLoadingLogout(false);
        onClose?.();
      }
    },
    [beforeLogoutHook, customLogout, handleLogout],
  );

  const { Dialog: DialogLogout, open: openLogout } = useLogoutDialog({
    isLoading: isLoadingLogout,
    onConfirm: onConfirmLogout,
  });

  return {
    ...mutate,
    handleLogout,
    DialogLogout,
    openLogout,
  };
}
