import { ArrowRight, IconProps } from '@phosphor-icons/react';
import classNames from 'classnames';
import {
  CustomizationUnit,
  applyCustomization,
} from 'common/repositories/customization';
import { MaybeText } from 'components/common/maybe';
import { TextProps } from 'components/elements/text';
import React, { useId } from 'react';
import structuralStyles from 'styles/layout.css';

import { ListItemButtonStyles } from './styles.css';

export type CustomizationListItemButtonType = {
  root?: CustomizationUnit<React.HTMLAttributes<HTMLElement>>;
  button?: CustomizationUnit<React.ComponentProps<'button'>>;
  label?: {
    root?: CustomizationUnit<React.ComponentProps<'div'>>;
    text?: TextProps;
    id?: string;
  };
  trailing?: IconProps & {
    hidden?: boolean;
  };
};

export interface ListItemButtonProps {
  leading?: React.ReactNode;
  onClick?: () => void;
  children: React.ReactNode;
  trailing?: React.ReactNode;

  /** This button doesn't appear inside an <ul> or <ol> */
  individual?: boolean;
  disabled?: boolean;
  border?: boolean;

  customization?: CustomizationListItemButtonType;
}

export default function ListItemButton(props: ListItemButtonProps) {
  const {
    leading: icon,
    children,
    trailing: right,
    onClick,
    individual,
    customization,
    border = true,
    disabled,
  } = props;
  const reactId = useId();
  const labelId =
    customization?.label?.id ?? `kurosim--button--list-item--${reactId}`;

  const innards = (
    <>
      {icon}
      <div
        {...applyCustomization(
          {
            className: classNames(
              structuralStyles.flexbox(),
              structuralStyles.fill({
                flex: true,
              }),
            ),
          },
          [customization?.label?.root],
        )}
      >
        <MaybeText
          textColor="foregroundPrimary"
          textVariant="body1Regular"
          ta="left"
          {...customization?.label?.text}
          id={
            typeof children === 'string' || typeof children === 'number'
              ? labelId
              : undefined
          }
        >
          {children}
        </MaybeText>
      </div>
      {customization?.trailing?.hidden
        ? null
        : right ||
          (onClick && !disabled && (
            <ArrowRight size={24} {...customization?.trailing} />
          ))}
    </>
  );

  const buttonCustomization = {
    className: classNames(
      !border ? undefined : ListItemButtonStyles.showBorder,
      ListItemButtonStyles.cursor({ disabled: !onClick || !!disabled }),
      structuralStyles.padding({ vertical: 12 }),
      ListItemButtonStyles.buttonLayout,
    ),
    style: disabled
      ? ({
          opacity: 0.7,
          cursor: 'not-allowed',
        } as React.CSSProperties)
      : undefined,
  };
  const listCustomization = {
    className: classNames(
      ListItemButtonStyles.cursor({ disabled: !onClick || !!disabled }),
      structuralStyles.fill({ width: true }),
      structuralStyles.padding({ horizontal: 16 }),
    ),
    style: disabled
      ? {
          opacity: 0.7,
          cursor: 'not-allowed',
        }
      : undefined,
  };

  if (individual) {
    return (
      <button
        type="button"
        aria-labelledby={labelId}
        {...applyCustomization(
          {
            className: classNames(
              listCustomization.className,
              buttonCustomization.className,
            ),
            style: {
              ...listCustomization.style,
              ...buttonCustomization.style,
            },
          },
          [customization?.root, customization?.button],
        )}
        aria-disabled={disabled || !onClick}
        disabled={disabled}
        onClick={onClick}
      >
        {innards}
      </button>
    );
  }

  return (
    <li
      aria-labelledby={labelId}
      {...applyCustomization(listCustomization as any, [customization?.root])}
    >
      <button
        type="button"
        {...applyCustomization(buttonCustomization as any, [
          customization?.button,
        ])}
        aria-disabled={disabled || !onClick}
        disabled={disabled}
        onClick={onClick}
      >
        {innards}
      </button>
    </li>
  );
}
