export class HighlightInstallationStamp {
  static readonly INSTALLATION_STAMP = 'highlight-installation-stamp';

  static get() {
    return (
      localStorage.getItem(HighlightInstallationStamp.INSTALLATION_STAMP) ===
      'true'
    );
  }

  static set() {
    localStorage.setItem(HighlightInstallationStamp.INSTALLATION_STAMP, 'true');
    return true;
  }
}
